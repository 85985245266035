import request from './request'
import {uuid} from 'vue-uuid'

const StandingOrder = {
  getStandingRecord(){
    return request({
      url: `/v1/standingorder/lists`,
      method: "get"
    });
  },
  addRecord(data){
    data.idempotency = uuid.v1()
    return request({
      url: `/v1/standingorder/add`,
      method: "post",
      data: data
    });
  },
  cancelRecord(uid){
    return request({
      url: `/v1/standingorder/cancel/${uid}`,
      method: "put",
      data: {idempotency: uuid.v1()}
    });
  },
  viewRecord(uid){
    return request({
      url: `/v1/standingorder/view/${uid}`,
      method: "get"
    });
  }
  
}

export default StandingOrder