<template>
  <div class="container-fluid mt--6">
    <div class="row">
      <div class="col-xl-12">
        <div class="card mb-3">
          <div class="card-header position-relative">
              <h3 class="mb-0">Standing Order</h3>
              <div class="position-absolute top-2 right-2 pt-1 pr-1">
                <router-link :to="{ path: '/standing-order/add'}" class="btn btn-success btn-block">{{ (isMobile ? 'Make' : 'Make Standing Order') }}</router-link>
              </div>
            </div>

          <div class="card-body" v-loading="loading">
            <el-tabs type="card">
              <el-tab-pane label="Active">
                <!-- Introduced -->
                <el-table :data="tableDataActive" border style="width: 100%" size="mini">
                  <el-table-column prop="name" label="Payee" width="180"></el-table-column>
                  <el-table-column prop="accountnumber" label="ACC No/IBAN" align="center" width="120"></el-table-column>
                  <el-table-column prop="amount" label="Amount" align="center" width="120">
                    <template slot-scope="scope">
                      {{ `${scope.row.currency} ${scope.row.amount}` }}
                    </template>
                  </el-table-column>
                  <el-table-column prop="reference" label="Reference" align="center" width="120"></el-table-column>
                  <el-table-column prop="startdate" label="Start Date" align="center" width="120"></el-table-column>
                  <el-table-column prop="frequency" label="Frequency" align="center" width="120"></el-table-column>
                  <el-table-column prop="enddate" label="End Date" align="center" width="120"></el-table-column>
                  <el-table-column prop="count" label="Count" align="center" width="120"></el-table-column>
                  <el-table-column prop="uid" label="Action" align="center" width="200">
                    <template slot-scope="scope">
                      <el-button type="primary" size="mini" @click="viewStandingOrder(scope.row)">View</el-button>
                      <el-button type="danger" size="mini" @click="cancelStandingOrder(scope.row)">Cancel</el-button>
                    </template>
                  </el-table-column>
                </el-table>
              </el-tab-pane>
              <el-tab-pane label="Cancelled">
                <el-table :data="tableDataCancelled" border style="width: 100%" size="mini">
                  <el-table-column prop="name" label="Payee" width="180"></el-table-column>
                  <el-table-column prop="accountnumber" label="ACC No/IBAN" align="center" width="120"></el-table-column>
                  <el-table-column prop="amount" label="Amount" align="center" width="120">
                    <template slot-scope="scope">
                      {{ `${scope.row.currency} ${scope.row.amount}` }}
                    </template>
                  </el-table-column>
                  <el-table-column prop="reference" label="Reference" align="center" width="120"></el-table-column>
                  <el-table-column prop="startdate" label="Start Date" align="center" width="120"></el-table-column>
                  <el-table-column prop="frequency" label="Frequency" align="center" width="120"></el-table-column>
                  <el-table-column prop="enddate" label="End Date" align="center" width="120"></el-table-column>
                  <el-table-column prop="count" label="Count" align="center" width="120"></el-table-column>
                  <el-table-column label="Action" align="center" width="200">
                    <template slot-scope="scope">
                      <el-button type="primary" size="mini" @click="viewStandingOrder(scope.row)">View</el-button>
                    </template>
                  </el-table-column>
                </el-table>
              </el-tab-pane>
            </el-tabs>
          </div>
        </div>
      </div>

      <el-dialog title="Payment results" :visible.sync="dialogTableVisible">
        <el-table :data="standardOrderList" border size="mini">
          <el-table-column property="datetime" label="Date" align="center" width="150"></el-table-column>
          <el-table-column property="message" label="Message" ></el-table-column>
          <el-table-column property="status" label="Status"  align="center" width="150"></el-table-column>
        </el-table>
      </el-dialog>


    </div>

  </div>
</template>

<script>
import StandingOrderApi from '@/api/standingorder'
import { isMobile } from "@/utils/helpers";
export default {
  computed: {
    isMobile() {
      return isMobile();
    }
  },
  data() {
    return {
      dialogTableVisible: false,
      loading: false,
      tableDataActive: [],
      tableDataCancelled: [],
      standardOrderList: [],
    };
  },
  methods: {
    getStandingOrder(){
      this.loading = true
      StandingOrderApi.getStandingRecord().then(({result, data, message})=>{
        this.loading = false
        if(result){
          if(data.active){
            this.tableDataActive = data.active
          }
          if(data.cancelled){
            this.tableDataCancelled = data.cancelled
          }
        }else{
          this.$message.error(message)
        }
      })
    },
    cancelRecordApi(data){
       StandingOrderApi.cancelRecord(data.uid).then(({result, message})=>{
        if(result){
          this.$swal(`Message`, `Cancel Standing Order is success`, 'success').then(()=>{
            this.getStandingOrder()
          })
        }else{
          this.$swal('Message', message, 'error')
        }
      })
    },
    cancelStandingOrder(data){
     this.$swal({
          title: 'Confirmation',
          html: `This will cancel Standing Order Payee: ${data.name} Acc No. ${data.accountnumber} Amount: ${data.currency} ${data.amount}. Continue?`,
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: "#d33",
          confirmButtonText: "Comfirm",
          cancelButtonText: 'Cancel',
          showCloseButton: true,
          reverseButtons: true
        }).then((result) => {
          if(result.value) {
            this.cancelRecordApi(data)
          } else {
            this.$message(`Action Canceled`); 
          }
        })
    },
    viewStandingOrder(data){
      this.standardOrderList = []
      this.loading = true
      StandingOrderApi.viewRecord(data.uid).then(({result, data})=>{
        this.loading = false
        if(result){
          this.standardOrderList = data
          this.dialogTableVisible = true
        }
      })
    }
  },
  created(){
    this.getStandingOrder()
  }
};
</script>

<style>
</style>